<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :class="{ active : modelValue }">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <ul class="tabs_btn">
                <li :class="tab === 'recover' ? 'active' : ''" @click="tab = 'recover'">Recovery Page</li>
                <li :class="tab === 'seo' ? 'active' : ''" @click="tab = 'seo'">SEO &amp; Tracking</li>
            </ul>
            <div class="tabs_content">
                <div class="loader_area2" v-if="recoveryPageLoader"><quote-loader/></div>
                <button type="button" class="toggle_bar" @click="toggleSectionbar" v-show="!recoveryPageLoader"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <Form @submit="handleSubmit" v-slot="{ errors }" class="setting-form" v-show="!recoveryPageLoader">
                    <div class="content_area">
                        <div class="content_wpr">
                            <div class="section_content w-100" v-if="tab === 'recover'">
                                <div class="section_header">
                                    <h2>Public Url</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr has_suffix">
                                                <input type="text" v-model="recoveryPage.resubscribe_url">
                                                <span class="suffix pointer" @click="handleCopy()">Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'recover'">
                                <div class="section_header">
                                    <h2>Recovery Page Setting</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <label for="card_icon" class="switch_option capsule_btn pb-4">
                                            <h5 class="large">Display Logo</h5>
                                            <input type="checkbox" id="card_icon" v-model="form.has_logo" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mt-1 mb-3" v-if="form.has_logo">
                                            <image-upload v-model="form.logo" image-type="coach-logo" :crop="true" />
                                        </div>
                                        <label for="card_image" class="switch_option capsule_btn pb-4">
                                            <h5 class="large">Display Cover</h5>
                                            <input type="checkbox" id="card_image" v-model="form.has_cover" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="upload_image mt-1 mb-3" v-if="form.has_cover">
                                            <image-upload v-model="form.cover" image-type="public-cover" :crop="true" />
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Page headline</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                    <Field autocomplete="off" v-model="form.headline" name="headline">
                                                        <textarea name="" v-model="form.headline" placeholder="ex: Accidentally unsubscribed from Thrive Coach email list?"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="headline" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Page sub headline</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.sub_headline }">
                                                    <Field autocomplete="off" v-model="form.sub_headline" name="sub_headline" label="sub headline">
                                                        <textarea v-model="form.sub_headline" placeholder="ex: Fill out the form below and then check your email"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="sub_headline" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Submit button text</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.button_text }">
                                                    <Field autocomplete="off" name="button_text" v-model="form.button_text" type="text" placeholder="ex: Resubscribe" rules="required" label="button text" />
                                                </div>
                                                <ErrorMessage name="button_text" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Submit button color</label>
                                                <Field autocomplete="off" name="button_color" v-model="form.button_color" type="text" placeholder="ex: Resubscribe" rules="required" label="button color">
                                                    <color-picker v-model="form.button_color" :classes="{ 'has-error': errors.button_color }" />
                                                </Field>
                                                <ErrorMessage name="button_color" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Submit button text color</label>
                                                <Field autocomplete="off" name="button_text_color" v-model="form.button_text_color" type="text" placeholder="ex: Resubscribe" rules="required" label="button text color">
                                                    <color-picker v-model="form.button_text_color" :classes="{ 'has-error': errors.button_text_color }" />
                                                </Field>
                                                <ErrorMessage name="button_text_color" class="text-danger" />
                                            </div>
                                        </div>

                                        <label for="privacy" class="switch_option capsule_btn border-0 mt-2">
                                            <h5 class="large">Privacy</h5>
                                            <input type="checkbox" id="privacy" v-model="form.is_security" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="form_grp p-0 mt-2 mb-4" v-if="form.is_security">
                                            <div class="group_item">
                                                <label class="input_label">Link To Privacy Policy</label>
                                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.privacy_url }">
                                                    <Field autocomplete="off" name="privacy_url" type="text" placeholder="ex. https://yourprivacyurl.com" v-model="form.privacy_url" rules="url" label="privacy url" />
                                                    <span class="prefix">URL</span>
                                                </div>
                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                <ErrorMessage name="privacy_url" class="text-danger" />
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Privacy Text</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.privacy_text }">
                                                    <Field autocomplete="off" type="text" name="privacy_text" v-model="form.privacy_text" />
                                                </div>
                                                <ErrorMessage name="privacy_text" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'seo'" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO Settings</h2>
                                </div>
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" name="seo_title" v-model="form.seo_title" type="text" />
                                                </div>
                                                <span><small>The title will be displayed in browser tabs.</small></span>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Keywords</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" name="seo_keyword" v-model="form.seo_keyword" type="text" />
                                                </div>
                                                <span><small>Several keywords that describe your page best.</small></span>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" name="seo_desc" v-model="form.seo_desc" type="text" />
                                                </div>
                                                <span><small>A longer description of your landing page.</small></span>
                                            </div>
                                        </div>
                                        <label for="seo_index" class="switch_option capsule_btn m-0 border-0 ">
                                            <h5 class="large">I want my page indexed by search engine</h5>
                                            <input type="checkbox" id="seo_index" v-model="form.seo_index" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'seo'">
                                <div class="section_header">
                                    <h2>Tracking</h2>
                                </div>
                                <div class="color_container">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Facebook Pixel Code</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                    <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.fb_pixel" label="description">
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.fb_pixel"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="fb_pixel" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'seo'" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <div class="setting_wpr">
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" name="seo_share_title" v-model="form.seo_share_title" type="text" />
                                                </div>
                                                <span><small>The title of the page visible when someone is sharing your landing page.</small></span>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="off" name="seo_share_desc" v-model="form.seo_share_desc" type="text" />
                                                </div>
                                                <span><small>A website description which appears when shared.</small></span>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">SEO Thumbnail</label>
                                                <div class="upload_image mt-1 mb-3">
                                                    <image-library v-model="form.seo_thumb" image-type="social-share" ref="member-id-cover-upload" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="tab === 'seo'" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <image-library v-model="form.seo_fav" image-type="favicon" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" class="preview_btn" @click="preview = true">Preview</button>
                    <div class="action_wpr">
                        <button :disabled="recoveryPageLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="recoveryPageLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="recoveryPageLoader"></i> {{ recoveryPageLoader ? 'Saving' : 'Save' }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'recover'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="full_preview" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="large">Full View</h5>
                            <input type="checkbox" id="full_preview" v-model="fullPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="recovery_page" :class="{'no_cover' : !form.has_cover && form.has_logo}">
                            <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner" v-if="form.has_cover">
                            <div class="content_body">
                                <img :src="form.logo ? form.logo : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="form.has_logo">
                                <h3>{{ form.headline }}</h3>
                                <h5>{{ form.sub_headline }}</h5>
                                <div class="setting_wpr">
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">First Name</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="ex: Robert">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Last Name</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="ex: Bacins">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">E-mail</label>
                                            <div class="field_wpr has_prefix">
                                                <input type="text" placeholder="hello.robert@gmail.com" >
                                                <span class="prefix"><i class="fas fa-envelope"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" :style="`background: ${form.button_color}; color: ${form.button_text_color}`" class="primary_btn">{{ form.button_text }}</button>
                                    <p class="privacy" v-if="form.is_security">{{ form.privacy_text }} <a :href="form.privacy_url">Privacy Policy</a></p>
                                    <p class="copyright">© Thrive Coach 2022. All rights reserved</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab === 'seo'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr" ref="seosection" @click="setting('seosetting')">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{ form.seo_title }}</h4>
                        <p>{{ form.seo_desc }}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="form.seo_thumb ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.seo_share_title ? form.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.seo_share_desc ? form.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <full-preview v-model="fullPreview">
                <div class="dashboard">
                    <div class="recovery_page" :class="{'no_cover' : !form.has_cover && form.has_logo}">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover.svg')" alt="" class="banner" v-if="device === 'desk' && form.has_cover">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-sm-cover.svg')" alt="" class="banner" v-if="device == 'tab' && form.has_cover">
                        <img :src="form.cover ? form.cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner" v-if="device == 'cell' && form.has_cover">
                        <div class="content_body">
                            <img :src="form.logo ? form.logo : require('@/assets/images/thumb/default-cover-square.svg')" alt="" class="icon" v-if="form.has_logo">
                            <h3>{{ form.headline }}</h3>
                            <h5>{{ form.sub_headline }}</h5>
                            <div class="setting_wpr">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">First Name</label>
                                        <div class="field_wpr">
                                            <input type="text" placeholder="ex: Robert">
                                        </div>
                                    </div>
                                    <div class="group_item" v-if="device !== 'cell'">
                                        <label class="input_label">Last Name</label>
                                        <div class="field_wpr">
                                            <input type="text" placeholder="ex: Bacins">
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp" v-if="device == 'cell'">
                                    <div class="group_item">
                                        <label class="input_label">Last Name</label>
                                        <div class="field_wpr">
                                            <Field autocomplete="off" type="text" name="last_name" placeholder="ex: Bacins" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">E-mail</label>
                                        <div class="field_wpr has_prefix">
                                            <input type="text" placeholder="hello.robert@gmail.com" >
                                            <span class="prefix"><i class="fas fa-envelope"></i></span>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" :style="`background: ${form.button_color}; color: ${form.button_text_color}`" class="primary_btn">{{ form.button_text }}</button>
                                <p class="privacy" v-if="form.is_security">{{ form.privacy_text }} <a :href="form.privacy_url">Privacy Policy</a></p>
                                <p class="copyright">© Thrive Coach 2022. All rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </full-preview>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'
    import { copyText } from 'vue3-clipboard'

    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
    const FullPreview = defineAsyncComponent(() => import('@/components/FullPreview'))

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Recovery Page',

        data() {
            return {
                tab: 'recover',
                preview: true,
                env: {},
                form: {
                    has_logo: 0,
                    logo: '',
                    has_cover: 0,
                    cover: '',
                    headline: 'Accidentally unsubscribed from {{company_name}} email list?',
                    sub_headline: 'Fill out the form below and then check your email',
                    button_text: 'Resubscribe',
                    button_color: '#ff5c6c',
                    button_text_color: '#ffffff',
                    is_security: 1,
                    privacy_url: '',
                    privacy_text: 'Your information is protected.',
                    seo_title: '',
                    seo_keyword: '',
                    seo_desc: '',
                    seo_index: 0,
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    fb_pixel: '',
                    seo_fav: '',
                },
                fullPreview: false,
                device: 'desk',
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            ImageUpload,
            FullPreview,
        },

        watch: {
            recoveryPage (page) {
                const vm = this;

                vm.form = JSON.parse(JSON.stringify(page));
                delete vm.form.created_at;
                delete vm.form.updated_at;
                delete vm.form.resubscribe_url;
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getRecoveryPage();
                    }, 1000);
                }else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            recoveryPage: state => state.commonModule.recoveryPage,
            recoveryPageLoader: state => state.commonModule.recoveryPageLoader,
        }),

        mounted () {
            const vm = this;
            vm.env = process.env;

            window.addEventListener("resize", this.myEventHandler);
            this.myEventHandler();
        },
        unmounted() {
            window.removeEventListener("resize", this.myEventHandler);
        },

        methods: {
            ...mapActions({
                getRecoveryPage: 'commonModule/getRecoveryPage',
                saveRecoveryPage: 'commonModule/saveRecoveryPage',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleSubmit (params, { setFieldError }) {
                const vm = this;
                const form = vm.form;

                form.setFieldError = setFieldError;

                vm.saveRecoveryPage(form);
            },

            handleCopy () {
                const vm = this;

                copyText(vm.recoveryPage.resubscribe_url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy url!');
                    } else {
                        Toastr.success('URL has been copied successfully!');
                    }
                })
            },
            setting (refName) {
                const vm = this;

                if (refName === 'hdrsetting') {
                    vm.tab = 'header';
                } else if (refName === 'cvrsetting') {
                    vm.tab = 'cover';
                } else if (refName === 'ftrsetting') {
                    vm.tab = 'footer';
                }

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function () {
                        let top = element.offsetTop;
                        document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                    }, 100);
                }, 100);
            },

            goTo (refName) {
                const vm = this;

                setTimeout(function () {
                    let element = vm.$refs[refName];

                    setTimeout(function() {
                        let top = element.offsetTop;
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }, 100);
                }, 10);
            },
            toggleSectionbar(){
                const ls_bar = document.querySelector('.global_setting');
                if(ls_bar.classList.contains('show_m_sidebar')){
                    ls_bar.classList.remove('show_m_sidebar');
                }else{
                    ls_bar.classList.add('show_m_sidebar');
                }
            },
            myEventHandler(){
                let screenWidth = window.innerWidth;
                if(screenWidth < 992){
                    this.preview = false;
                }else{
                    this.preview = true;
                }
            },
        },
    }
</script>

<style scoped>
    .area_body.desk .dashboard {
        align-items: center;
        background: #fafafb;
    }
    .area_body.tab .dashboard, .area_body.cell .dashboard {
        background: #fafafb;
    }
    .recovery_page {
        border: 1px solid #eaeaea;
        align-items: center;
        background: #fff;
        line-height: 0;
        width: 100%;
        flex-grow: 1;
    }

    .recovery_page .banner {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .recovery_page .content_body {
        padding: 25px 40px 40px 40px;
        max-width: 650px;
        margin: 0 auto;
    }

    .mail_brand {
        background: #e4efff;
        border-radius: 8px 8px 0 0;
        padding: 15px 20px;
    }

    .recovery_page h3 {
        font-size: 20px;
        line-height: 28px;
        color: #121525;
        font-weight: 400;
        padding-bottom: 10px;
        text-align: center;
    }

    .recovery_page .icon {
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        display: block;
        border: 2px solid #fff;
    }
    .desk .recovery_page .icon{
        width: 180px;
        height: 180px;
        margin: -120px auto 50px auto;
    }
    .desk .recovery_page .setting_wpr{
        margin-top: 30px;
    }
    .desk .recovery_page .form_grp{
        margin-bottom: 10px;
    }
    .desk .primary_btn{
        margin: 40px 0 10px 0;
    }
    .tab .recovery_page .icon{
        width: 140px;
        height: 140px;
        margin: -95px auto 40px auto;
    }
    .tab .recovery_page .setting_wpr{
        margin-top: 20px;
    }
    .tab .primary_btn{
        margin: 30px 0 0 0;
    }
    .cell .recovery_page .icon{
        width: 100px;
        height: 100px;
        margin: -75px auto 30px auto;
    }
    .cell .recovery_page .setting_wpr{
        margin-top: 20px;
    }

    .recovery_page.no_cover{
        margin-top: 70px;
    }

    .recovery_page h5 {
        font-size: 15px;
        line-height: 20px;
        color: #5a5a5a;
        font-weight: 400;
        text-align: center;
        padding-bottom: 10px;
    }

    .recovery_page .checkbox {
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 18px;
        margin: 25px 0;
    }

    .recovery_page .privacy {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 25px 0 10px;
    }

    .recovery_page .privacy a {
        color: #2f7eed;
        text-decoration: none;
    }

    .recovery_page .copyright {
        font-size: 14px;
        line-height: 18px;
        color: #666;
        font-weight: 400;
        text-align: center;
        padding: 10px 0 0;
    }

    .preview_area {
        display: block;
    }

    .preview_area .close_btn.right {
        right: 15px;
        top: 15px;
        left: auto;
        position: absolute;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 40px auto;
    }
    .tab .recovery_page.no_cover{
        margin-top: 70px;
    }
    .cell .recovery_page.no_cover{
        margin-top: 70px;
    }

    .cell .recovery_page .content_body {
        padding: 25px;
        line-height: 0;
    }
    .cell .recovery_page .icon{
        width: 100px;
        height: 100px;
        border: 3px solid #fff;
        margin-top: -75px;
    }
    .cell .recovery_page h3 {
        font-size: 18px;
        line-height: 26px;
    }
    .cell .recovery_page h5{
        font-size: 13px;
        line-height: 16px;
    }
    .preview_content {
        position: relative;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .cell .recovery_page .privacy{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
    }
    .cell .recovery_page .copyright{
        font-size: 11px;
        line-height: 16px;
        font-weight: 400;
        color: #5a5a5a;
        padding: 0;
    }
    .cell .form_grp{
        padding: 13px 0;
    }
    .cell .setting_wpr .group_item .input_label{
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 4px;
    }
    .cell .field_wpr{
        border-radius: 4px;
    }
    .cell .field_wpr input{
        height: 35px;
        font-size: 13px;
    }
    .cell .field_wpr.has_prefix{
        padding-left: 40px;
    }
    .cell .field_wpr.has_prefix .prefix{
        width: 40px;
    }
    .cell .field_wpr.has_prefix .prefix i{
        font-size: 14px;
    }
    .cell .primary_btn{
        height: 40px;
        font-size: 13px;
        border-radius: 4px;
        margin: 25px 0 0 0;
    }
    /* @media(max-width: 1400px){
        .preview_area .preview_area .recovery_page{
            transform: scale(0.8);
        }
    } */
    .setting-form {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    .upload_image :deep(.tab_content){
        height: auto;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 5px;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .browser_layout {
        background: #f5f5f5;
        border-radius: 5px;
        padding: 5px 0 2px 0;
        max-width: 450px;
        margin: 12px 0;
    }

    .browser_tabs {
        display: flex;
        padding: 0 10px;
    }

    .browser_tabs li {
        padding: 7px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        background: #fff;
        flex: 0 0 150px;
        position: relative;
    }

    .browser_tabs li:before {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 0 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        left: -8px;
    }

    .browser_tabs li:after {
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 8px 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        right: -8px;
    }

    .browser_tabs li h6 {
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #5a5a5a;
    }

    .browser_tabs li i {
        margin-left: auto;
        font-size: 10px;
        color: #5a5a5a;
    }

    .browser_tabs li img {
        max-width: 15px;
        height: auto;
        margin-right: 7px;
    }

    .browser_tabs li:last-child {
        flex: 0 0 auto;
        background: transparent;
        padding: 10px;
        position: static;
    }

    .browser_tabs li:last-child i {
        color: #121525;
    }

    .browser_info {
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        margin: 0 2px;
        border-radius: 3px;
    }

    .browser_info i {
        font-size: 10px;
        margin: 0 5px;
    }

    .browser_info img.user {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }

    .browser_search {
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 6px;
        border-radius: 12px;
        margin: 0 10px;
        flex: 1;
    }

    .browser_search p,
    .browser_search i {
        font-size: 9px;
        line-height: 11px;
    }

    @media(max-width: 1399px){
        .tabs_content .setting_wpr .form_grp {
            row-gap: 30px;
        }
        .tabs_content .setting_wpr .group_item{
            min-width: 190px;
        }
    }
</style>
